import React, { useState, useEffect, Fragment } from 'react'
import { useStore } from '@nanostores/react'
import { cartData, isCartOpen } from '@stores/cartStore'

import Loader from '@components/Loader/Loader.jsx'
import ShoppingBag from '@components/Icons/ShoppingBag.jsx'
const CartCount = React.lazy(() =>
    import('@components/Header/GlobalNav/CartCount.jsx')
)

const NavCart = () => {
    const $cartData = useStore(cartData || null)
    const $isCartOpen = useStore(isCartOpen)
    const [numberOfItems, setNumberOfItems] = useState()

    // // console.log $cartData in NavCart', $cartData)

    useEffect(() => {
        let items = []
        let numItems
        if ($cartData) {
            items = $cartData.lines
            if (items) {
                numItems = items
                    .map((item) => item.node.quantity)
                    .reduce((a, b) => a + b, 0)
            }

            setNumberOfItems(numItems)

            //
        }
    }, [$cartData])

    return (
        <div className="nav-cart relative mr-1">
            {/* <ShoppingBag width="w-[16px]" /> */}
            <span
                title="Your Cart"
                className="text-ash cursor-pointer text-sm"
                onClick={() => {
                    // console.log Cart clicked')
                    isCartOpen.set(!$isCartOpen)
                }}
            >
                <ShoppingBag width="w-[20px]" />
            </span>
            {numberOfItems &&
            numberOfItems !== null &&
            numberOfItems !== 0 &&
            numberOfItems !== '0' &&
            numberOfItems !== undefined &&
            numberOfItems !== 'undefined' &&
            typeof $cartData.lines !== 'undefined' &&
            $cartData.lines &&
            $cartData.lines.length > 0 ? (
                <CartCount numberOfItems={numberOfItems} />
            ) : null}
        </div>
    )
}

export default NavCart
